import React, { useEffect, useState } from "react";
import SetupTab from "../components/SetupTab.jsx";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

import { ReactComponent as GmailIcon } from "../../../imgs/icons/gmail.svg";
import { ReactComponent as IntercomIcon } from "../../../imgs/icons/intercom.svg";
import { ReactComponent as UploadFileIcon } from "../../../imgs/icons/uploadFile.svg";
import { ReactComponent as WebsiteSitemapIcon } from "../../../imgs/icons/chrome.svg";

import GlobalLoadingSpinner from "../../../components/GlobalSpinner.jsx";

import api from "../../../api.js";

import AddSourceModal from "./AddSourceModal.jsx";
import AddWebsiteModal from "./AddWebsiteModal.jsx";
import AddQsAndAsModal from "./AddQsAndAsModal.jsx";
import AddPoliciesModal from "./AddPoliciesModal.jsx";
import AddGmailModal from "./AddGmailModal.jsx";
import AddIntercomModal from "./AddIntercomModal.jsx";
import AddUploadedFiles from "./AddUploadedFiles.jsx";

const KnowledgeSources = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddWebsiteModalVisible, setIsAddWebsiteModalVisible] =
    useState(false);

  const [isQsAndAsModalVisible, setIsQsAndAsModalVisible] = useState(false);
  const [isPoliciesModalVisible, setIsPoliciesModalVisible] = useState(false);
  const [isGmailModalVisible, setIsGmailModalVisible] = useState(false);
  const [isIntercomModalVisible, setIsIntercomModalVisible] = useState(false);
  const [isUploadFilesModalVisible, setIsUploadFilesModalVisible] = useState(false)

  const steps = {
    website: () => setIsAddWebsiteModalVisible(true),
    custom: () => setIsQsAndAsModalVisible(true),
    policies: () => setIsPoliciesModalVisible(true),
    gmail: () => setIsGmailModalVisible(true),
    intercom: () => setIsIntercomModalVisible(true),
    files: () => setIsUploadFilesModalVisible(true),
  };

  const [loading, setLoading] = useState(true);
  const [knowledgeSources, setKnowledgeSources] = useState([]);

  const disableSource = async source_id => {
    setLoading(true);
    try {
      const response = await api.put(`/api/uploads/${source_id}`, {
        is_live: false,
      });

      fetchKnowledgeSources();
    } catch (error) {
      console.error("Error updating source:", error);
    }
  };

  const enableSource = async source_id => {
    setLoading(true);
    try {
      const response = await api.put(`/api/uploads/${source_id}`, {
        is_live: true,
      });

      fetchKnowledgeSources();
    } catch (error) {
      console.error("Error updating source:", error);
    }
  };

  const fetchKnowledgeSources = async () => {
    try {
      const response = await api.get(`/api/uploads`, {
        params: { chatbot_uuid: localStorage.getItem("chatbot_uuid") },
      });

      setKnowledgeSources(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetchings uploaded sources:", error);
    }
  };

  useEffect(() => {
    fetchKnowledgeSources();
  }, []);

  const renderIcon = type => {
    switch (type) {
      case "GMAIL":
        return <GmailIcon className="w-6 h-6 mr-2 text-red-500" />;
      case "INTERCOM":
        return <IntercomIcon className="w-6 h-6 mr-2 text-blue-500" />;
      case "TEXT":
      case "FILE":
        return <UploadFileIcon className="w-6 h-6 mr-2 texXTgray-500" />;
      case "URL":
        return <WebsiteSitemapIcon className="w-6 h-6 mr-2 text-green-500" />;
      default:
        return null;
    }
  };

  const renderTitle = type => {
    switch (type) {
      case "GMAIL":
        return "Gmail";
      case "INTERCOM":
        return "Intercom";
      case "TEXT":
      case "FILE":
        return "Document Upload";
      case "URL":
        return "Website";
      default:
        return null;
    }
  };

  const toggleAll = () => {
    const allChecked = !Object.values(checkBoxes).every(Boolean);
    const newCheckBoxes = Object.keys(checkBoxes).reduce(
      (acc, id) => ({ ...acc, [id]: allChecked }),
      {}
    );
    setCheckBoxes(newCheckBoxes);
  };

  const handleCheckboxChange = id => {
    setCheckBoxes(prev => {
      const newCheckBoxes = { ...prev, [id]: !prev[id] };
      if (!newCheckBoxes[id]) {
        newCheckBoxes.all = false;
      }
      return newCheckBoxes;
    });
  };

  const [checkBoxes, setCheckBoxes] = useState(
    knowledgeSources.reduce(
      (acc, source) => ({ ...acc, [source.id]: false }),
      {}
    )
  );

  return (
    <div className="mt-12 mx-4 md:mx-24">
      <GlobalLoadingSpinner loading={loading} />
      <SetupTab
        title={"Knowledge Sources"}
        subtitle={
          "Use this section to setup everything related to your AI employee’s automation."
        }
        activeTab={"knowledge-sources"}
      />

      <div className="bg-white p-6 mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-md font-semibold">Knowledge Sources</h2>
            <p className="text-sm text-gray-600">
              These are used by your AI employee to learn how to respond to
              customers accurately.
            </p>
          </div>
          <button
            className="flex items-center px-4 py-2 border-2 border-gray-200 text-gray-700 text-xs font-medium rounded-lg hover:bg-gray-200"
            onClick={() => setIsModalVisible(true)}
          >
            + Add source
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border border-gray-200 rounded-lg">
            <thead>
              <tr>
                <th className="py-0 px-6 text-left text-gray-600 text-sm font-medium">
                  <div className="inline-flex items-center">
                    <input
                      type="checkbox"
                      onClick={toggleAll}
                      defaultChecked={Object.values(checkBoxes).every(Boolean)}
                      className="hidden mr-6 appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-gray-300 checked:border-transparent focus:outline-none transition duration-200"
                    />
                    <span>Content Type</span>
                  </div>
                </th>
                <th className="py-4 px-6 text-left text-gray-600 text-sm font-medium">
                  Status
                </th>
                {/* <th className="py-4 px-6 text-left text-gray-600 font-medium">
                  Pages Synced
                </th>
                <th className="py-4 px-6 text-left text-gray-600 font-medium">
                  Used in Responses
                </th> */}
                <th className="py-4 px-6 text-left text-gray-600 text-sm font-medium">
                  Characters Used
                </th>
                <th className="py-4 px-6"></th>
              </tr>
            </thead>
            <tbody>
              {knowledgeSources.map((source, index) => (
                <tr key={index} className="border-t hover:bg-gray-50">
                  <td className="py-4 px-6 flex items-center">
                    <input
                      type="checkbox"
                      checked={checkBoxes[source.id]}
                      onChange={() => handleCheckboxChange(source.id)}
                      className="hidden pt-2 mr-3 appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-gray-300 checked:border-transparent focus:outline-none transition duration-200"
                    />
                    {renderIcon(source.data_source_name)}
                    <div className="ml-1">
                      <div className="font-medium">
                        {renderTitle(source.data_source_name)}
                      </div>
                      <span className="text-gray-500 text-sm">
                        {source.source_name}
                      </span>
                    </div>
                  </td>

                  <td className="py-4 px-6">
                    <span className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium border shadow">
                      <span
                        className={`mr-1 w-2 h-2 rounded-full ${source.is_live ? "bg-green-500" : "bg-gray-500"}`}
                      ></span>
                      {source.is_live ? "Active" : "Inactive"}
                    </span>
                  </td>

                  {/* <td className="py-4 px-6">
                    <span className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium border shadow">
                      {source.pagesSynced}
                    </span>
                  </td>

                  <td className="py-4 px-6">
                    <span className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium border shadow">
                      {source.responses}
                    </span>
                  </td> */}

                  <td className="py-4 px-6">
                    <p className="font-medium text-gray-900">
                      {(
                        source.charactersUsed ||
                        Math.round(Math.random() * 100000)
                      ).toLocaleString()}{" "}
                      <small>(* randomly gen)</small>
                    </p>
                  </td>

                  <td className="py-4 px-6 text-right">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          {source.is_live ? (
                            <a
                              className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                              onClick={() => disableSource(source.id)}
                            >
                              Disable
                            </a>
                          ) : (
                            <a
                              className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                              onClick={() => enableSource(source.id)}
                            >
                              Enable
                            </a>
                          )}
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalVisible && (
        <AddSourceModal
          onClose={() => setIsModalVisible(false)}
          steps={steps}
        />
      )}

      <AddWebsiteModal
        isOpen={isAddWebsiteModalVisible}
        onClose={() => setIsAddWebsiteModalVisible(false)}
        goBack={() => {
          setIsModalVisible(true);
          setIsAddWebsiteModalVisible(false);
        }}
        onSuccess={fetchKnowledgeSources}
        api={api}
        setLoading={setLoading}
      />

      <AddQsAndAsModal
        isOpen={isQsAndAsModalVisible}
        onClose={() => setIsQsAndAsModalVisible(false)}
        goBack={() => {
          setIsModalVisible(true);
          setIsQsAndAsModalVisible(false);
        }}
        onSuccess={fetchKnowledgeSources}
        api={api}
        setLoading={setLoading}
      />

      <AddPoliciesModal
        isOpen={isPoliciesModalVisible}
        onClose={() => setIsPoliciesModalVisible(false)}
        goBack={() => {
          setIsModalVisible(true);
          setIsPoliciesModalVisible(false);
        }}
        onSuccess={fetchKnowledgeSources}
        api={api}
        setLoading={setLoading}
      />

      <AddGmailModal
        isOpen={isGmailModalVisible}
        onClose={() => setIsGmailModalVisible(false)}
        goBack={() => {
          setIsModalVisible(true);
          setIsGmailModalVisible(false);
        }}
        onSuccess={fetchKnowledgeSources}
        api={api}
        setLoading={setLoading}
        loading={loading}
      />

      <AddIntercomModal
        isOpen={isIntercomModalVisible}
        onClose={() => setIsIntercomModalVisible(false)}
        goBack={() => {
          setIsModalVisible(true);
          setIsIntercomModalVisible(false);
        }}
        onSuccess={fetchKnowledgeSources}
        api={api}
        setLoading={setLoading}
        loading={loading}
      />

      <AddUploadedFiles
        isOpen={isUploadFilesModalVisible}
        onClose={() => setIsUploadFilesModalVisible(false)}
        goBack={() => {
          setIsModalVisible(true);
          setIsUploadFilesModalVisible(false);
        }}
        onSuccess={fetchKnowledgeSources}
        api={api}
        setLoading={setLoading}
        loading={loading}
      />
    </div>
  );
};

export default KnowledgeSources;
