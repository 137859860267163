import React from "react";
import { useNavigate } from "react-router-dom";

const SetupTab = ({ title, subtitle, activeTab }) => {
  const navigate = useNavigate();
  const setActiveTab = newTab => {
    if (newTab === activeTab) return;

    navigate(`/setup/${newTab}`);
  };
  return (
    <div>
      <h1 className="text-lg sm:text-xl">{title}</h1>
      <h5 className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
        {subtitle}
      </h5>
      <div className="mb-8">
        <nav className="flex space-x-1 sm:space-x-4 border-b-2 text-xs sm:text-lg">
          <button
            className={`font-semibold px-3 py-2 rounded-md ${activeTab === "knowledge-sources" ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
            onClick={() => setActiveTab("knowledge-sources")}
          >
            Knowledge Sources
          </button>
          <button
            className={`font-semibold px-3 py-2 rounded-md ${activeTab === "branding" ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
            onClick={() => setActiveTab("branding")}
          >
            Branding
          </button>
          <button
            className={`font-semibold px-3 py-2 rounded-md ${activeTab === "intents" ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
            onClick={() => setActiveTab("intents")}
          >
            Intents
          </button>
          <button
            className={`font-semibold px-3 py-2 rounded-md ${activeTab === "test" ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
            onClick={() => setActiveTab("test")}
          >
            Test
          </button>
          <button
            className={`font-semibold px-3 py-2 rounded-md ${activeTab === "deploy" ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
            onClick={() => setActiveTab("deploy")}
          >
            Deploy
          </button>
        </nav>
      </div>
    </div>
  );
};

export default SetupTab;
