import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/file.svg";
import { ReactComponent as HelpIcon } from "../../../imgs/icons/help.svg";

import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as BackButton } from "../../../imgs/icons/buttons/back.svg";

const AddQsAndAsModal = ({
  isOpen,
  onClose,
  goBack,
  onSuccess,
  setLoading,
  api,
}) => {
  const [qas, setQas] = useState([{ question: "", answer: "" }]);

  if (!isOpen) return null;

  const actionDisabled = qas.some(
    qa => !qa.question.trim() || !qa.answer.trim()
  );

  const repeatField = () => {
    setQas([...qas, { question: "", answer: "" }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedQas = [...qas];
    updatedQas[index][field] = value;
    setQas(updatedQas);
  };

  const removeField = index => {
    const updatedQas = qas.filter((_, i) => i !== index);
    setQas(updatedQas);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center">
          <TitleIcon width={60} height={60} className="p-4 ml-6 mt-6 border rounded-xl" />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">
            Add Questions & Answers
          </h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            These will be used to train your AI Agent in the best way to respond
            to certain questions. Your AI will take this and will put its own
            twist based on your brand guidelines to make it more conversational.
            You do not need to perfect the tone here.
          </p>
        </div>

        <div className=" border-t border-1 border-red">
          {qas.map((qa, index) => (
            <div key={index} className="mb-4 mx-6">
              <div className="flex justify-between items-center">
                <div className="flex mt-6 mb-2 text-xs text-gray-800 font-semibold">
                  Question *
                  <HelpIcon className="ml-1 w-4 h-4" />
                </div>
                {qas.length > 1 && index > 0 && (
                  <button
                    onClick={() => removeField(index)}
                    className="text-gray-400 hover:text-red-500 focus:outline-none"
                  >
                    &times;
                  </button>
                )}
              </div>
              <textarea
                className="px-2 py-3 rounded-md border border-gray-300 ring-opacity-50 w-full"
                placeholder="e.g. How much does it cost?"
                rows={2}
                value={qa.question}
                onChange={e =>
                  handleInputChange(index, "question", e.target.value)
                }
              />

              <div className="flex mt-3 mb-2 text-xs text-gray-800 font-semibold">
                Answer *
                <HelpIcon className="ml-1 w-4 h-4" />
              </div>
              <textarea
                className="px-2 py-3 rounded-md border border-gray-300 ring-opacity-50 w-full"
                placeholder="e.g. Our gold package costs $299/month, our silver package costs $199/month and our bronze package costs $99/month. But if you provide me a bit more information about your needs, I can lead you in the right direction as to which package is best for you."
                rows={4}
                value={qa.answer}
                onChange={e =>
                  handleInputChange(index, "answer", e.target.value)
                }
              />
            </div>
          ))}

          <button
            onClick={repeatField}
            className="mx-6 my-3 text-sm text-secondary font-semibold hover:underline focus:outline-none"
          >
            + Add another
          </button>
        </div>

        <div className="flex items-center py-3">
          <button
            onClick={goBack}
            className="flex items-center justify-center w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            <BackButton className="mr-2" />
            Go Back
          </button>
          <button
            className={`flex items-center justify-center w-1/2 text-md leading-5 bg-secondary border border-primary shadow-md ${actionDisabled ? "cursor-not-allowed" : "hover:opacity-75"
              } font-bold text-white ml-1 mr-6 py-3 rounded-md`}
            disabled={true}
          >
            {qas.length === 1 ? "Add Question" : "Add Questions"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddQsAndAsModal;
