import React from "react";
import { useNavigate } from "react-router-dom";
import { authenticateWithParagon } from "../../paragonAuthHelper.js";
import api from "../../api.js";
import { paragon } from "@useparagon/connect";

import WizardStep from "../WizardStep.jsx";

import { useIngestionWizard } from "../IngestionWizardContext";

import { ReactComponent as GoogleIcon } from "../../imgs/icons/google.svg";
import { ReactComponent as UploadFileIcon } from "../../imgs/icons/uploadFile.svg";

const GmailSupport = () => {
  const navigate = useNavigate();

  const { prevWizardRoute, nextWizardRoute } = useIngestionWizard();

  const handleNextClick = () => {
    navigate(nextWizardRoute());
  };

  const navigateBack = () => {
    navigate(prevWizardRoute());
  };

  const handleSkipClick = () => {
    navigate(nextWizardRoute());
  };

  const handleGoogleSignIn = async () => {
    try {
      const companyId = sessionStorage.getItem("company_id");
      if (!companyId) {
        console.error("Company ID not found in sessionStorage");
        return;
      }

      await authenticateWithParagon(companyId);
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }

    await paragon.connect("gmail", {
      onSuccess: () => {
        console.log("Connected to Gmail successfully");
      },
      onError: error => {
        console.error("Error connecting to Gmail:", error);
      },
    });
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post("/api/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <WizardStep
        handleNextClick={handleNextClick}
        handleSkipStepClick={handleSkipClick}
        handleBackClick={navigateBack}
      >
        <div className="flex flex-col items-center justify-center max-w-sm">
          <h1 className="text-2xl mb-3 text-center mx-auto">
            Let's grab your Gmail support conversations to train your AI Support
            Team
          </h1>

          <p className="mt-4">Pull conversations from Support Gmail</p>

          <button
            className="bg-black text-sm font-bold text-white px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full max-w-[300px] justify-center mx-auto mb-2"
            onClick={handleGoogleSignIn}
          >
            Sign in to Google
            <GoogleIcon className="h-5 w-5 ml-2" />
          </button>

          <label className="cursor-pointer text-sm font-bold text-gray-400 px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full max-w-[300px] min-w-[300px] justify-center mx-auto">
            Upload email history file
            <UploadFileIcon className="h-5 w-5 ml-2" />
            <input type="file" className="hidden" onChange={handleFileUpload} />
          </label>

          <a
            href="https://support.google.com/mail/answer/10759745"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 underline font-bold text-xs pb-10 mx-auto text-center justify-center mt-5"
          >
            How to export email history from Gmail
          </a>
        </div>
      </WizardStep>
    </div>
  );
};

export default GmailSupport;
