import { DateTime } from "luxon";

export function humanize(str) {
  return str.replace(/_/g, " ").replace(/\b\w/g, c => c.toUpperCase());
}

export const formatCompactTime = utcDateString => {
  const now = DateTime.local();
  const parsedDate = DateTime.fromISO(utcDateString, { zone: "utc" });
  const diff = now.diff(parsedDate, ["minutes", "hours", "days", "weeks", "months"]);

  const timeUnits = [
    { value: diff.as("months"), unit: "month", short: "month" },
    { value: diff.as("weeks"), unit: "week", short: "week" },
    { value: diff.as("days"), unit: "day", short: "day" },
    { value: diff.as("hours"), unit: "hour", short: "hour" },
    { value: diff.as("minutes"), unit: "minute", short: "min" },
  ];

  for (const { value, short } of timeUnits) {
    const flooredValue = Math.floor(value);
    if (flooredValue >= 1) return `${flooredValue} ${short}${flooredValue > 1 ? "s" : ""} ago`;
  }

  return "just now";
};
