import React from "react";
import {
  CheckBadgeIcon,
  MapPinIcon,
  InboxArrowDownIcon,
} from "@heroicons/react/20/solid";
import { humanize } from "../../utils";
import ConversationSummary from "../../components/ConversationSummary";

const MessageDetails = ({ selectedThread, resolveConversation }) => {
  const intents =
    selectedThread?.intents.map(intent => humanize(intent.type)) || [];

  return (
    <div className="bg-white max-w-[250px] p-4 hidden xl:block">
      {selectedThread && (
        <div className="space-y-4">
          {/* TODO: Replace with real values */}

          <div className="opacity-25 flex items-center space-x-4">
            {selectedThread.user_avatar && (
              <div className="w-16 h-16 overflow-hidden rounded-full border-2 border-gray-300">
                <img
                  src={selectedThread.user_avatar}
                  alt="User Avatar"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            {!selectedThread.user_avatar && (
              <div className="w-12 h-12 bg-emerald-500 text-white text-xl font-bold flex items-center justify-center rounded-full uppercase">
                U
              </div>
            )}
            <div>{selectedThread.email || "User"} offline</div>
          </div>

          <div className="flex items-center space-x-2 text-gray-700">
            <MapPinIcon
              aria-hidden="true"
              className="ml-2 h-12 w-12 text-emerald-500"
            />

            <div className="text-sm font-semibold">
              {selectedThread.location || "Unspecified location"}
              <span className="text-sm font-normal">
                {" "}
                - {selectedThread.current_time || "Unknown"}
              </span>
            </div>
          </div>
          {/* ~ END ~ TODO: Replace with real values */}

          <div className="flex items-center space-x-2">
            <span className="text-sm italic flex items-center">
              <InboxArrowDownIcon
                aria-hidden="true"
                className="ml-2 h-5 w-5 text-emerald-500"
              />
              <span className="ml-1">
                {selectedThread?.customer?.email || "Unknown email"}
              </span>
            </span>
          </div>

          {selectedThread.resolved && (
            <span className="text-sm font-semibold flex items-center">
              <CheckBadgeIcon
                aria-hidden="true"
                className="ml-2 h-5 w-5 text-emerald-500"
              />
              <span className="ml-1">Resolved</span>
            </span>
          )}

          {selectedThread.handed_off && (
            <span className="text-sm font-semibold flex items-center">
              <CheckBadgeIcon
                aria-hidden="true"
                className="ml-2 h-5 w-5 text-blue-500"
              />
              <span className="ml-1">Handed off</span>
            </span>
          )}

          {!selectedThread.resolved && (
            <div>
              <span className="text-sm font-semibold flex items-center">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md px-3 py-2 text-xs font-semibold hover:bg-gray-50 bg-gray-100 border-2 text-emerald-500"
                  onClick={() => {
                    resolveConversation(selectedThread.conversation_uuid);
                  }}
                >
                  Resolve
                </button>
              </span>
            </div>
          )}

          <div>
            <ul className="list-disc list-inside text-sm">
              <ConversationSummary conversation={selectedThread} />
            </ul>
          </div>

          {intents.length > 0 && (
            <div>
              <h4 className="text-sm font-semibold">Intents:</h4>
              <ul className="list-disc list-inside text-sm">
                {intents.map((intent, index) => (
                  <li key={index}>{intent}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageDetails;
