import React from "react";

import { ReactComponent as GlobeIcon } from "../../../imgs/icons/globe.svg";

const GlobeInput = ({ value, setValue, isValid }) => {
  return (
    <div
      className={`flex ${isValid ? " " : "border-red-500"} items-center border rounded-lg px-4 py-2 bg-white shadow-sm focus-within:border-gray-500 focus-within:ring focus-within:ring-gray-300 focus-within:ring-opacity-50`}
    >
      <div className="mr-2 text-gray-500">
        <GlobeIcon className="w-4 h-4" />
      </div>
      <input
        type="text"
        placeholder="https://example.com/home"
        value={value}
        onChange={val => setValue(val)}
        className={`flex-1 bg-transparent outline-none text-gray-700 placeholder-gray-400`}
      />
    </div>
  );
};

export default GlobeInput;
