import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    const token = hashParams.get("access_token");

    if (token) {
      setAccessToken(token);
    } else {
      setErrorMessage("Invalid or missing access token.");
    }
  }, []);

  const handlePasswordUpdate = async () => {
    setErrorMessage("");

    if (!accessToken) {
      setErrorMessage("Access token is missing or invalid.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    const { error: sessionError } = await supabase.auth.setSession({
      access_token: accessToken,
      refresh_token: "<refresh_token>", // TODO: check this parameter is strictly needed (doco suggests it is)
    });

    if (sessionError) {
      setErrorMessage("Failed to set session: " + sessionError.message);
      return;
    }

    const { data, error } = await supabase.auth.updateUser({
      password: password,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage(
        "Password has been set successfully. You can now log in."
      );
      setTimeout(() => {
        navigate("/onboarding/signin");
      }, 10000);
    }
  };

  return (
    <div className="flex min-h-full flex-1 bg-[#F0F0EF] justify-center items-center">
      <div className="flex flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24 bg-white rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center">
          Set Your Password
        </h2>

        <form className="mt-6 space-y-6" onSubmit={e => e.preventDefault()}>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                type="password"
                id="password"
                placeholder="Enter new password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm Password
            </label>
            <div className="mt-2">
              <input
                type="password"
                id="confirm-password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              onClick={handlePasswordUpdate}
              className="flex w-full justify-center rounded-md bg-[#30a46c] px-3 py-1.5 text-sm font-semibold leading-6 text-white"
            >
              Set Password
            </button>
          </div>
        </form>

        {errorMessage && (
          <p className="mt-3 text-xs text-red-500 text-center">
            {errorMessage}
          </p>
        )}

        {successMessage && (
          <>
            <p className="mt-3 text-xs text-green-500 text-center">
              {successMessage}
            </p>
            <button
              type="button"
              onClick={() => navigate("/onboarding/signin")}
              className="flex w-full justify-center rounded-md bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white"
            >
              Back to login
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
