import React, { useState } from "react";
import { GoogleOutlined, ApiOutlined, ShopOutlined } from "@ant-design/icons";
import ComingSoonBadge from "../components/ComingSoonBadge";
import { paragon } from "@useparagon/connect";
import { authenticateWithParagon } from "../paragonAuthHelper.js";
import ConfirmationDialog from "../components/ConfirmationDialog";

const Integrations = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const integrations = [
    {
      name: "Gmail",
      description:
        "Connect your chatbot to Gmail and manage email interactions.",
      icon: <GoogleOutlined className="text-red-600 text-4xl" />,
    },
    {
      name: "Zapier",
      description: "Connect your chatbot with thousands of apps using Zapier.",
      icon: <ApiOutlined className="text-orange-500 text-4xl" />,
      comingSoon: true, // Zapier is marked as coming soon
    },
    {
      name: "Shopify",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: <ShopOutlined className="text-green-500 text-4xl" />,
      comingSoon: true, // Shopify is marked as coming soon
    },
  ];

  const handleCardClick = integration => {
    if (!integration.comingSoon) {
      setSelectedIntegration(integration);
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    const companyId = sessionStorage.getItem("company_id");

    if (!companyId) {
      console.error("Company ID not found in sessionStorage");
      return;
    }

    authenticateWithParagon(companyId)
      .then(() => {
        paragon.connect("gmail", {
          onSuccess: () => {
            console.log("Connected to Gmail successfully");
          },
          onError: error => {
            console.error("Error connecting to Gmail:", error);
          },
        });
      })
      .catch(error => {
        console.error("Error authenticating with Paragon:", error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-2xl font-bold text-center mb-8">Integrations</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {integrations.map(integration => (
          <div
            key={integration.name}
            className="relative border rounded-lg shadow-lg p-6 bg-white hover:shadow-xl transition duration-300 cursor-pointer"
            onClick={() => handleCardClick(integration)}
          >
            <div className="flex justify-center mb-4">{integration.icon}</div>
            <h2 className="text-lg font-semibold text-center mb-2 flex justify-center items-center">
              {integration.name}
              {integration.comingSoon && (
                <ComingSoonBadge name="Coming Soon" />
              )}{" "}
              {/* Apply the badge */}
            </h2>
            <p className="text-center text-gray-600">
              {integration.description}
            </p>
            <div className="mt-4 flex justify-center">
              <button
                className={`px-4 py-2 rounded-md text-white transition duration-300 ${
                  integration.comingSoon
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
                disabled={integration.comingSoon} // Disable the button for coming soon integrations
              >
                {integration.comingSoon ? "Unavailable" : "Connect"}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalVisible && (
        <ConfirmationDialog
          isVisible={isModalVisible}
          onClose={handleCancel}
          onCancel={handleCancel}
          onConfirm={handleOk}
          title={`Enable ${selectedIntegration?.name}`}
          description={`Are you sure you want to enable the ${selectedIntegration?.name} integration?`}
          confirmLabel="Enable"
          cancelLabel="Cancel"
          useCase="positive"
        />
      )}
    </div>
  );
};

export default Integrations;
