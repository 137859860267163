import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api"; // Ensure this path is correct based on your project structure
import WizardStep from "./WizardStep.jsx";

const EnterWebsiteURL = () => {
  const [websiteURL, setWebsiteURL] = useState("");
  const navigate = useNavigate();

  const handleURLChange = e => {
    setWebsiteURL(e.target.value);
  };

  const handleNextClick = async () => {
    console.log(`Entered website URL: ${websiteURL}`);
    try {
      const response = await api.post("/api/process-url", {
        url: websiteURL,
      });

      if (response.status === 200) {
        console.log("URL processed successfully");
        navigate("/onboarding/gmail-support");
      } else {
        console.error("Failed to process URL");
      }
    } catch (error) {
      console.error("Error processing URL:", error);
    }
  };

  const handleSkipStepClick = () => {
    navigate("/onboarding/select-content");
  };

  const navigateBack = () => {
    navigate("/onboarding/use-case");
  };

  return (
    <div>
      <WizardStep
        handleNextClick={handleNextClick}
        handleSkipStepClick={handleSkipStepClick}
        handleBackClick={navigateBack}
        nextEnabled={!!websiteURL}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="w-full max-w-sm px-4 mt-20">
            <h1 className="text-3xl mb-5 text-center w-full">
              Enter your website URL
            </h1>

            <p className="text-lg mb-5 text-center">
              We'll grab content from your website and FAQ to help train your
              new AI Support Employee
            </p>
          </div>
          <input
            type="url"
            className="url-input mt-4 p-2 border rounded w-full max-w-[300px] bg-gray-100"
            placeholder="https://www.example.com/faq"
            value={websiteURL}
            onChange={handleURLChange}
          />
        </div>
      </WizardStep>
    </div>
  );
};

export default EnterWebsiteURL;
