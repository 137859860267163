import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as UploadFileIcon } from "../../imgs/icons/uploadFile.svg";

import WizardStep from "../WizardStep.jsx";

import { useIngestionWizard } from "../IngestionWizardContext";

import api from "../../api.js";

const FileUploadSupport = () => {
  const navigate = useNavigate();

  const { prevWizardRoute, nextWizardRoute } = useIngestionWizard();

  const handleNextClick = () => {
    navigate(nextWizardRoute());
  };

  const navigateBack = () => {
    navigate(prevWizardRoute());
  };

  const handleSkipClick = () => {
    navigate(`/onboarding/subscription`); // TODO: make dynamic
  };

  const handleFileUpload = async event => {
    // Question?: should we support multiple file upload?
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      // TODO:
      //  1. loading state
      //  2. indicate the file(s) has been uploaded
      const response = await api.post("/api/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div>
      <WizardStep
        handleNextClick={handleNextClick}
        handleSkipStepClick={handleSkipClick}
        handleBackClick={navigateBack}
      >
        <div className="flex flex-col items-center justify-center max-w-sm">
          <h1 className="text-2xl mb-3 text-center mx-auto">
            Let's upload your file to help train your AI Support Team
          </h1>
          <p className="mt-4">Upload conversations from Support Gmail</p>

          <label className="cursor-pointer text-sm font-bold text-gray-400 px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full max-w-[300px] min-w-[300px] justify-center mx-auto">
            Upload file
            <UploadFileIcon className="h-5 w-5 ml-2" />
            <input type="file" className="hidden" onChange={handleFileUpload} />
          </label>
        </div>
      </WizardStep>
    </div>
  );
};

export default FileUploadSupport;
