import React, { useState, useEffect } from "react";

import { CheckBadgeIcon } from "@heroicons/react/20/solid";

import GenericAvatar from "./GenericAvatar";

import api from "../../api.js";

import { formatCompactTime } from "../../utils/dates.js";

const ThreadCard = ({ thread, selected, selectMessage }) => {
  const [messagePreview, setMessagePreview] = useState("");

  useEffect(() => {
    const fetchPreview = async () => {
      try {
        const response = await api.get(
          `/api/conversation/${thread.conversation_uuid}`
        );

        setMessagePreview(response.data[0].text);
      } catch (error) {
        setMessagePreview("");
        console.error("Failed to fetch preview:", error);
      }
    };

    fetchPreview();
  }, [thread.conversation_uuid]); // Run only when conversation_uuid changes

  const threadEmail = (thread) => {
    const title = thread.email || "Unknown email";

    if (title.length > 13) {
      return `${title.substring(0, 13)}...`;
    }

    return title;
  }

  return (
    <li
      key={thread.conversation_id}
      className={`relative p-4 cursor-pointer rounded border-b border-slate-200 border-r ${
        selected ? "bg-[#F4F3F1]" : "bg-white"
      } flex flex-col h-full max-w-xs max-h-24 min-h-24`}
      onClick={() => selectMessage(thread)}
    >
      <div className="flex items-start justify-between relative">
        <div className="flex items-start flex-1">
          {thread.user_avatar && (
            <div>
              <div className="w-8 h-8 overflow-hidden rounded-full border-2 border-gray-300">
                <img
                  src={thread.user_avatar}
                  alt="User Avatar"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          )}
          {!thread.user_avatar && (
            <GenericAvatar />
          )}
          <div className="ml-3 flex flex-col">
            <div className="flex items-center justify-between">
              <div className="flex space-x-0">
                <span className="font-bold text-sm text-gray-900 mr-1">
                  {threadEmail(thread)}
                </span>
                {thread.handed_off && (
                  <CheckBadgeIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-blue-500"
                  />
                )}
                {thread.resolved && (
                  <CheckBadgeIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-emerald-500"
                  />
                )}
              </div>
              <span className="text-xs text-gray-500">
                {formatCompactTime(thread.last_message_time)}{" "}
              </span>
            </div>
            <span className="text-sm text-gray-700 line-clamp-2 mt-1 min-w-56 max-w-56">
              {messagePreview}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ThreadCard;
