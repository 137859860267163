import { useState } from "react";
import { supabase } from "../supabaseClient";

const RecoverPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleRecoverPassword = async e => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (!email) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_APP_URL}/password-reset?type=recovery&access_token`,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage(
        "If this email is associated to an account, a password reset link has been sent to your email address."
      );
    }
  };

  const issueMagicLink = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    // TODO: Confirm we're ok with automatic signups with this flow [MVP]
    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_APP_URL}/callback`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setSuccessMessage("Magic link in the making, please check your email.");
    }
  };

  return (
    <div className="flex min-h-full flex-1 bg-[#F0F0EF] justify-center items-center">
      <div className="flex flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24 bg-white rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center">
          Recover Your Password
        </h2>

        <form className="mt-6 space-y-6" onSubmit={handleRecoverPassword}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email Address
            </label>
            <div className="mt-2">
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-[#30a46c] px-3 py-1.5 text-sm font-semibold leading-6 text-white"
            >
              Get Recovery Link
            </button>
          </div>
        </form>

        <div className="mt-2">
          <button
            onClick={issueMagicLink}
            type="submit"
            className="flex w-full justify-center rounded-md bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white"
          >
            Get a magic link
          </button>
        </div>

        {errorMessage && (
          <p className="mt-3 text-xs text-red-500 text-center">
            {errorMessage}
          </p>
        )}

        {successMessage && (
          <p className="mt-3 text-xs text-emerald-600 text-center">
            {successMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default RecoverPassword;
