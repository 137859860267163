import axios from 'axios'

export const WIDGET_URL = 'https://d20v41bdithrkw.cloudfront.net';

const api = axios.create({
    baseURL: 'https://api.supportmagic.co'
});

api.interceptors.request.use(config => {
    const token = sessionStorage.getItem('supabase_jwt');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;
