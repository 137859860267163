import React, { createContext, useState, useContext } from "react";

const IngestionWizardContext = createContext();

export const useIngestionWizard = () => {
  return useContext(IngestionWizardContext);
};

export const IngestionWizardProvider = ({ children, initialSteps }) => {
  const [steps, setSteps] = useState(initialSteps || []);
  const [currentIndex, setCurrentIndex] = useState(0);

  const saveSteps = newSteps => {
    // TODO: call API to persist against current user account

    sessionStorage.setItem("wizardSteps", JSON.stringify(newSteps));
    setSteps(newSteps);
    setCurrentIndex(-1);
  };

  const routeFromStep = index => {
    const step = steps[index];

    switch (step) {
      case "Gmail":
        return "/onboarding/gmail-support";
      case "Intercom":
        return "/onboarding/intercom-support";
      case "Upload file":
        return "/onboarding/file-upload-support";
      default:
        return undefined;
    }
  };

  const nextWizardRoute = () => {
    const index = currentIndex + 1;
    setCurrentIndex(index);

    return routeFromStep(index) || "/onboarding/subscription";
  };

  const prevWizardRoute = () => {
    const index = currentIndex - 1;
    setCurrentIndex(index);

    return routeFromStep(index) || "/onboarding/select-content";
  };

  return (
    <IngestionWizardContext.Provider
      value={{
        saveSteps,
        nextWizardRoute,
        prevWizardRoute,
      }}
    >
      {children}
    </IngestionWizardContext.Provider>
  );
};
