import React, { useState, useEffect, useCallback } from "react";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import { Switch } from "@headlessui/react";

import GlobalLoadingSpinner from "../../components/GlobalSpinner.jsx";

import api from "../../api.js";
import IntentFormDialog from "../../components/IntentFormDialog.jsx";
import SetupTab from "./components/SetupTab.jsx";

import { formatCompactTime } from "../../utils/dates";

const Intents = () => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [intentToDelete, setIntentToDelete] = useState(undefined);

  // ? TODO: Eventually link up with templates ?
  const [defaultIntents, setDefaultIntents] = useState([]);
  const [editableIntents, setEditableIntents] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingIntent, setEditingIntent] = useState(null);
  const [formData, setFormData] = useState({
    type: "",
    description: "",
    response: "",
  });

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalIntents, setTotalIntents] = useState(0);

  const totalPages = Math.ceil(totalIntents / pageSize);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const fetchIntents = useCallback(async () => {
    const chatbotUuid = localStorage.getItem("chatbot_uuid");
    if (!chatbotUuid) {
      console.error("Chatbot UUID not found");
      return;
    }
    try {
      setLoading(true);
      const { data } = await api.get(`/api/intents`, {
        params: { chatbot_uuid: chatbotUuid, page, page_size: pageSize },
      });

      setDefaultIntents(data.filter(int => int.default));
      setEditableIntents(data.filter(int => !int.default));
    } catch (err) {
      console.error("Failed to fetch intents", err);
      setDefaultIntents([]);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize]);

  useEffect(() => {
    fetchIntents();
  }, [fetchIntents]);

  const handlePageSizeChange = event => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const badgeClassesByIntentType = intent => {
    return intent.default
      ? "text-green-700 bg-green-50 ring-green-600/20"
      : "text-gray-600 bg-gray-50 ring-gray-500/10";
  };

  const handleEdit = intent => {
    setEditingIntent(intent);

    setFormData({
      type: intent.type,
      description: intent.description,
      response: intent.response,
    });
    showModal();
  };

  const handleRemove = async intent => {
    try {
      await api.delete(`/api/intents/${intent.id}`);
      setIsConfirmModalVisible(false);
      fetchIntents();
    } catch (err) {
      console.error("Failed to remove intent", err);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingIntent(null);
    setFormData({
      type: "",
      description: "",
      response: "",
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCreateOrUpdate = async evt => {
    setLoading(true);
    evt.preventDefault();

    try {
      const chatbotUuid = localStorage.getItem("chatbot_uuid");
      if (editingIntent) {
        await api.put(`/api/intents/${editingIntent.id}`, {
          ...formData,
          chatbot_uuid: chatbotUuid,
        });
      } else {
        await api.post("/api/intents", {
          ...formData,
          chatbot_uuid: chatbotUuid,
        });
      }
      fetchIntents();
      handleCancel();
    } catch (err) {
      console.error("Failed to create or update intent", err);
    }
  };

  return (
    <div className="mt-12 mx-4 md:mx-24">
      <SetupTab
        title={"Intents"}
        subtitle={
          "Utilize intents to help your AI employee associate customer intents from conversations to actions the AI should take on your behalf. This provides you ultimate control to automate as much of your business operations as you want."
        }
        activeTab={"intents"}
      />

      <GlobalLoadingSpinner loading={loading} />

      <div className="bg-white p-6 mb-8">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-md font-semibold">Intents</h2>
            <p className="text-sm text-gray-600">
              Intents are used by your AI Agent to understand a specific
              customer intent and how to react in those situations.
            </p>
          </div>
          <button
            className="flex items-center px-4 py-2 border-2 border-gray-200 text-gray-700 text-xs font-medium rounded-lg hover:bg-gray-200"
            onClick={() => showModal()}
          >
            + Add intent
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full border border-gray-200 rounded-lg">
            <thead>
              <tr>
                <th className="py-4 px-6 text-left text-gray-600 text-xs font-medium">
                  Name
                </th>
                <th className="py-4 px-6 text-left text-gray-600 text-xs font-medium">
                  Category
                </th>
                <th className="py-4 px-6 text-left text-gray-600 text-xs font-medium">
                  Created at
                </th>
                <th className="py-4 px-6 text-left text-gray-600 text-xs font-medium">
                  Updated at
                </th>
                <th className="py-4 px-6"></th>
                <th className="py-4 px-6"></th>
              </tr>
            </thead>

            <tbody>
              {editableIntents.map((intent, index) => (
                <tr key={index} className="border-t hover:bg-gray-50 text-sm">
                  <td className="py-4 px-6">
                    <p className="text-gray-700">{intent.description}</p>
                  </td>

                  <td className="py-4 px-6">
                    <p className="font-medium text-gray-800">
                      <span className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium border shadow">
                        <span
                          className={`mr-1 w-2 h-2 rounded-full bg-green-500`}
                        ></span>
                        {intent.type}
                      </span>
                    </p>
                  </td>

                  <td className="py-4 px-6">
                    <p className="font-medium text-gray-900">
                      {formatCompactTime(intent.created_at)}
                    </p>
                  </td>

                  <td className="py-4 px-6">
                    <p className="font-medium text-gray-900">
                      {formatCompactTime(intent.updated_at)}
                    </p>
                  </td>

                  <td className="py-4 px-6">
                    <p className="font-medium text-gray-900">
                      <Switch
                        checked={intent.is_live}
                        onChange={() => alert("Coming soon...")}
                        className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                      </Switch>
                    </p>
                  </td>

                  <td className="py-4 px-6 text-right">
                    <Menu as="div" className="relative flex-none">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <a
                            className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            onClick={() => handleEdit(intent)}
                          >
                            Edit
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            onClick={() => {
                              setIntentToDelete(intent);
                              setIsConfirmModalVisible(true);
                            }}
                          >
                            Delete
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalVisible && (
        <IntentFormDialog
          isVisible={isModalVisible}
          editingIntent={editingIntent}
          formData={formData}
          handleInputChange={handleInputChange}
          handleCreateOrUpdate={handleCreateOrUpdate}
          handleCancel={handleCancel}
        />
      )}

      {isConfirmModalVisible && (
        <ConfirmationDialog
          isVisible={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(false)}
          onCancel={() => {
            setIntentToDelete(undefined);
            setIsConfirmModalVisible(false);
          }}
          onConfirm={() => {
            handleRemove(intentToDelete);
          }}
          title={"Delete intent?"}
          description={"Are you sure you want to delete this intent?"}
          confirmLabel="Yes"
          cancelLabel="Cancel"
          useCase="negative"
        />
      )}

      {/* // TODO: Add pagination if need be */}
      {/* <div className="flex items-center justify-between border-t bg-white px-4 py-3 sm:px-6 mt-4">
        <div className="flex items-center">
          <p className="text-sm text-gray-700">
            Page <span className="font-medium">{page}</span> of{" "}
            <span className="font-medium">{totalPages}</span>
          </p>
        </div>

        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            onClick={handlePreviousPage}
            disabled={page === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => setPage(index + 1)}
              className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === index + 1
                ? "bg-emerald-600 text-white"
                : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </nav>

        <div className="flex items-center">
          <label htmlFor="page-size" className="mr-2 text-sm text-gray-700">
            Page size:
          </label>
          <select
            id="page-size"
            value={pageSize}
            onChange={handlePageSizeChange}
            className="border-gray-300 rounded-md text-sm p-1.5"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div> */}
    </div>
  );
};

export default Intents;
