import React from "react";

const ComingSoonBadge = ({ name }) => {
  return (
    <span className="ml-2 inline-flex items-center rounded-md bg-gray-50 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 opacity-1000 text-red">
      {name}
    </span>
  );
};

export default ComingSoonBadge;
