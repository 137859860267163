import React, { useState, useRef } from "react";
import CompanyInfo from "./CompanyInfo";
import Team from "./Team/Team";
import PlanBilling from "./PlanBilling";

const Settings = () => {
  const [tabKey, setTabKey] = useState("1");
  const accountTopRef = useRef(null);

  return (
    <div className="flex flex-col h-screen overflow-y-auto bg-white shadow-md rounded-lg">
      <div ref={accountTopRef} />
      <h1 className="sr-only">Account Settings</h1>

      <div className="sticky top-0 h-65px bg-white border-b border-gray-200 z-10">
        <nav className="flex justify-start py-4 bg-white">
          <ul
            role="list"
            className="flex gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-600 sm:px-6 lg:px-8"
          >
            <li key="company-info">
              <button
                className={
                  tabKey === "1" ? "text-emerald-600" : "text-gray-600"
                }
                onClick={() => setTabKey("1")}
              >
                Company info
              </button>
            </li>

            <li key="team">
              <button
                className={
                  tabKey === "2" ? "text-emerald-600" : "text-gray-600"
                }
                onClick={() => setTabKey("2")}
              >
                Team
              </button>
            </li>

            <li key="billing">
              <button
                className={
                  tabKey === "3" ? "text-emerald-600" : "text-gray-600"
                }
                onClick={() => setTabKey("3")}
              >
                Plan & billing
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <div className="flex-1 divide-y divide-gray-200 overflow-y-auto">
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div className="md:col-span-3">
            {tabKey === "1" && <CompanyInfo />}
            {tabKey === "2" && <Team accountTopRef={accountTopRef} />}
            {tabKey === "3" && <PlanBilling />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
