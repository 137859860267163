import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import api from "../api.js";
import GlobalLoadingSpinner from "../components/GlobalSpinner.jsx";
import ConfirmationDialog from "../components/ConfirmationDialog";

import MessageList from "./AIInbox/MessageList.jsx";
import Thread from "./AIInbox/Thread.jsx";
import MessageDetails from "./AIInbox/MessageDetails.jsx";
import { fakeConversations } from "./AIInbox/fakeConversations.js";

import { useLocation, useNavigate } from "react-router-dom";

const AIInbox = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const chatbotUuid = localStorage.getItem("chatbot_uuid");
  const location = useLocation();
  const navigate = useNavigate();

  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [inResolutionConversationUUID, setInResolutionConversationUUID] =
    useState(null);

  const [filters, setFilters] = useState({
    resolved: false,
    handedOff: false,
  });

  const [conversationUUID, setConversationUUID] = useState(
    new URLSearchParams(location.search).get("only")
  );

  const resolveConversation = async conversationUuid => {
    setInResolutionConversationUUID(conversationUuid);
    setIsConfirmModalVisible(true);
  };

  const confirmResolveConversation = async () => {
    try {
      setLoading(true);

      await api.post("/api/update-conversation", {
        conversation_uuid: inResolutionConversationUUID,
        resolved: true,
      });

      fetchConversations(1, pageSize, filters);
    } catch (err) {
      // TODO: Use error modal
      console.error("Failed to update conversation", err);
    } finally {
      setIsConfirmModalVisible(false);
    }
  };

  const wsRef = useRef(null);
  let [totalThreads, setTotalThreads] = useState(0);
  const totalPages = Math.ceil(totalThreads / pageSize);

  const faking = false;

  const sortByLastMessageTime = conversations => {
    return conversations.sort(
      (a, b) =>
        DateTime.fromISO(b.last_message_time, { zone: "utc" }).toMillis() -
        DateTime.fromISO(a.last_message_time, { zone: "utc" }).toMillis()
    );
  };

  const fetchConversations = useCallback(
    async (page, pageSize, filters) => {
      if (!chatbotUuid) {
        console.error(
          "Chatbot UUID is not found in local storage or URL parameters"
        );
        return;
      }
      try {
        setLoading(true);

        if (faking) {
          setTotalThreads(fakeConversations.total_conversations);
          setThreads(sortByLastMessageTime(fakeConversations.conversations)); // TODO: sort on the server side
        } else {
          const apiFilters = {};

          if (filters.resolved) {
            apiFilters.resolved = true;
          }

          if (filters.resolved === false) {
            apiFilters.resolved = false;
          }

          if (filters.handedOff) {
            apiFilters.handed_off = true;
          }

          if (conversationUUID) {
            apiFilters.conversation_uuid = conversationUUID;
          }

          const { data } = await api.get(`/api/conversations/${chatbotUuid}`, {
            params: { page, page_size: pageSize, ...apiFilters },
          });

          setTotalThreads(data.total_conversations);
          setThreads(sortByLastMessageTime(data.conversations)); // TODO: sort on the server side
        }
      } catch (err) {
        console.error("Failed to fetch conversations", err);
      } finally {
        setLoading(false);
      }
    },
    [chatbotUuid, faking, conversationUUID, filters]
  );

  useEffect(() => {
    fetchConversations(page, pageSize, filters);
  }, [fetchConversations, page, pageSize, filters, conversationUUID]);

  useEffect(() => {
    const originalBackgroundColor =
      document.getElementById("root").style.backgroundColor;
    document.getElementById("root").style.backgroundColor = "white";
    return () => {
      document.getElementById("root").style.backgroundColor =
        originalBackgroundColor;
    };
  }, []);

  useEffect(() => {
    if (selectedThread) {
      wsRef.current = new WebSocket(
        `wss://api.supportmagic.co/ws/${selectedThread.conversation_uuid}`
      );
      wsRef.current.onmessage = () =>
        fetchThread({ thread: selectedThread, loading: false });
      return () => wsRef.current && wsRef.current.close();
    }
  }, [selectedThread]);

  useEffect(() => {
    if (threads.length) fetchThread({ thread: threads[0] });
  }, [threads]);

  const fetchThread = async ({ thread, loading = true }) => {
    try {
      if (loading) setLoading(true);
      const { data } = await api.get(
        `/api/conversation/${thread.conversation_uuid}`
      );
      const formattedMessages = data.map(entry => ({
        message: entry.text,
        sentTime: DateTime.fromISO(entry.created_at, { zone: "utc" })
          .setZone("local")
          .toFormat("EEE d MMM h:mm a"),
        sender:
          entry.direction === "outgoing"
            ? "user"
            : entry.is_human
              ? "admin"
              : "ChatBot",
        direction: entry.direction === "outgoing" ? "outgoing" : "incoming",
      }));
      setSelectedThread(thread);
      setMessages(formattedMessages);
    } catch (err) {
      console.error("Failed to fetch conversation details", err);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(prevPage => Math.max(prevPage - 1, 1));
    }
  };

  const handleRemoveUUIDFilter = () => {
    setLoading(true);
    setConversationUUID(null);
    navigate("/dashboard/ai-inbox");
    setLoading(false);
  };

  return (
    <div className="flex h-full">
      <GlobalLoadingSpinner loading={loading} />
      <div className="flex w-full h-full bg-white">
        <div
          className={`sticky top-[65px] h-[calc(100vh-65px)] lg:h-[calc(100vh)] min-w-[300px] max-w-[20vw] ${selectedThread && "hidden sm:block"
            } overflow-y-auto border-r border-gray-200`}
        >
          {conversationUUID && (
            <div className="flex justify-between space-x-2 px-4 py-2 bg-gray-100 rounded-full m-2">
              <span
                className="text-xs mt-1 cursor-pointer"
                title={conversationUUID}
              >
                <span className="font-bold">Conversation: </span>
                {conversationUUID.substring(0, 16)}...
              </span>
              <button
                className="cursor-pointer bg-red-500 text-white text-right rounded-full px-2 py-1 text-xs ml-auto font-bold"
                onClick={handleRemoveUUIDFilter}
              >
                X
              </button>
            </div>
          )}

          <MessageList
            selectedThread={selectedThread}
            threads={threads}
            fetchThread={fetchThread}
            totalThreads={totalThreads}
            currentPage={page}
            setPage={setPage}
            currentPageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={totalPages}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            filters={filters}
            setFilters={setFilters}
            showFilters={!conversationUUID}
          />
        </div>
        <div className="flex flex-grow border-r border-gray-200 overflow-y-auto">
          {selectedThread && (
            <Thread
              selectedThread={selectedThread}
              setSelectedThread={setSelectedThread}
              messages={messages}
              fetchThread={fetchThread}
              chatbotUuid={chatbotUuid}
              api={api}
            />
          )}
        </div>
        <div className="sticky top-[65px] h-[calc(100vh-65px)] hidden xl:block max-w-[20vw] min-w-[15vw]">
          {selectedThread && (
            <MessageDetails
              selectedThread={selectedThread}
              resolveConversation={resolveConversation}
            />
          )}
        </div>
      </div>

      {isConfirmModalVisible && (
        <ConfirmationDialog
          isVisible={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(false)}
          onCancel={() => setIsConfirmModalVisible(false)}
          onConfirm={() => confirmResolveConversation()}
          title={"Resolve conversation?"}
          description={"Are you sure you want to resolve this thread?"}
          confirmLabel="Yes"
          cancelLabel="Cancel"
          useCase="positive"
        />
      )}
    </div>
  );
};

export default React.memo(AIInbox);
