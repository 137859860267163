import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  ArrowLeftEndOnRectangleIcon,
  ChevronUpDownIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const UserProfilePopover = ({ user, onSignOut }) => {
  const navigate = useNavigate();

  return (
    <Popover className="relative z-50">
      <PopoverButton className="flex items-center w-full">
        <div className="flex items-center p-3 rounded-lg border-gray-300 border w-full">
          <img
            src={user.avatar}
            alt="User picture"
            className="h-10 w-10 rounded-full object-cover border border-gray-300"
          />
          <div className="ml-3 flex-1">
            <div className="text-sm font-semibold text-gray-900">
              {user.name}
            </div>
            <div className="text-xs text-gray-500">{user.email}</div>
          </div>
          <ChevronUpDownIcon className="h-5 w-5 text-gray-500 cursor-pointer hover:bg-gray-50" />
        </div>
      </PopoverButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute z-50 right-0 mb-2 w-48 origin-bottom-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transform translate-y-[-100%]">
          {({ close }) => (
            <div className="p-4">
              <div className="space-y-2">
                <button
                  className="flex items-center w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-md"
                  onClick={() => {
                    navigate("/dashboard/profile");
                    close();
                  }}
                >
                  <UserIcon className="h-5 w-5 mr-2 text-gray-500" />
                  Your Profile
                </button>
                <button
                  onClick={onSignOut}
                  className="flex items-center w-full px-3 py-2 text-sm text-red-600 hover:bg-red-100 rounded-md"
                >
                  <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2 text-red-500" />
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default UserProfilePopover;
