import React from "react";
import { useNavigate } from "react-router-dom";
import "./IntercomSupport.css";
import api from "../../api.js";
import { authenticateWithParagon } from "../../paragonAuthHelper.js";
import { paragon } from "@useparagon/connect";

import { ReactComponent as IntercomIcon } from "../../imgs/icons/intercom.svg";
import { ReactComponent as UploadFileIcon } from "../../imgs/icons/uploadFile.svg";

import WizardStep from "../WizardStep.jsx";

import { useIngestionWizard } from "../IngestionWizardContext";

const IntercomSupport = () => {
  const navigate = useNavigate();

  const { prevWizardRoute, nextWizardRoute } = useIngestionWizard();

  const handleNextClick = () => {
    navigate(nextWizardRoute());
  };

  const navigateBack = () => {
    console.log("oiii");
    navigate(prevWizardRoute());
  };

  const handleSkipClick = () => {
    navigate(nextWizardRoute());
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post("/api/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleIntercomSignIn = async () => {
    try {
      const companyId = sessionStorage.getItem("company_id");
      if (!companyId) {
        console.error("Company ID not found in sessionStorage");
        return;
      }

      await authenticateWithParagon(companyId);

      paragon.connect("intercom", {
        onSuccess: () => {
          console.log("Connected to Intercom successfully");
        },
        onError: error => {
          console.error("Error connecting to Intercom:", error);
        },
      });
    } catch (error) {
      console.error("Error during Intercom Sign-In:", error);
    }
  };

  // TODO: is this required?
  //
  // const callParagonWorkflow = async () => {
  //   try {
  //     const response = await paragon.workflow(
  //       "5422dcf8-968a-4a8a-b8f1-0c4798dbed56",
  //       {}
  //     );

  //     await api.post("/api/save-embedding", {
  //       data: response,
  //     });

  //     console.log("Paragon workflow response sent to backend:", response.data);
  //   } catch (error) {
  //     console.error("Error calling Paragon workflow:", error);
  //   }
  // };

  return (
    <div>
      <WizardStep
        handleNextClick={handleNextClick}
        handleSkipStepClick={handleSkipClick}
        handleBackClick={navigateBack}
      >
        <div className="flex flex-col items-center justify-center max-w-sm">
          <h1 className="text-2xl mb-3 text-center mx-auto">
            Let's grab your Intercom support conversations to train your AI
            Support Team
          </h1>

          <p className="mt-4">Pull conversations from Support Gmail</p>

          <button
            className="bg-black text-sm font-bold text-white px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full max-w-[300px] justify-center mx-auto mb-2"
            onClick={handleIntercomSignIn}
          >
            Sign in to Intercom
            <IntercomIcon className="h-5 w-5 ml-2" />
          </button>

          <label className="cursor-pointer text-sm font-bold text-gray-400 px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full max-w-[300px] min-w-[300px] justify-center mx-auto">
            Upload Intercom support history
            <UploadFileIcon className="h-5 w-5 ml-2" />
            <input type="file" className="hidden" onChange={handleFileUpload} />
          </label>

          <a
            href="https://www.intercom.com/help/en/articles/2046229-export-your-conversations-data"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 underline font-bold text-xs pb-10 mx-auto text-center justify-center mt-5"
          >
            How to export conversation data from Intercom
          </a>
        </div>
      </WizardStep>
    </div>
  );
};

export default IntercomSupport;
