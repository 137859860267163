export const validateURL = url => {
  try {
    const parsedUrl = new URL(url);

    if (parsedUrl.protocol !== "https:") return false;

    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!domainRegex.test(parsedUrl.hostname)) return false;

    return true;
  } catch (error) {
    return false;
  }
};
