import React, { useState } from "react";

import WizardStep from "./WizardStep.jsx";

import { ReactComponent as GmailIcon } from "../imgs/icons/gmail.svg";
import { ReactComponent as IntercomIcon } from "../imgs/icons/intercom.svg";
import { ReactComponent as MicrosoftIcon } from "../imgs/icons/microsoft.svg";
import { ReactComponent as GoogleDocsIcon } from "../imgs/icons/googleDocs.svg";
import { ReactComponent as GorgiasIcon } from "../imgs/icons/gorgias.svg";
import { ReactComponent as HubSpotIcon } from "../imgs/icons/hubspot.svg";
import { ReactComponent as KustomerIcon } from "../imgs/icons/kustomer.svg";
import { ReactComponent as LiveChatIcon } from "../imgs/icons/liveChat.svg";
import { ReactComponent as NotionIcon } from "../imgs/icons/notion.svg";
import { ReactComponent as TidioIcon } from "../imgs/icons/tidio.svg";
import { ReactComponent as UploadFileIcon } from "../imgs/icons/uploadFile.svg";
import { ReactComponent as WebsiteFAQIcon } from "../imgs/icons/websiteFAQ.svg";
import { ReactComponent as WebsiteSitemapIcon } from "../imgs/icons/websiteSitemap.svg";
import { ReactComponent as ZendeskIcon } from "../imgs/icons/zendesk.svg";

import { useIngestionWizard } from "./IngestionWizardContext";

import { useNavigate } from "react-router-dom";

import ComingSoonBadge from "../components/ComingSoonBadge.jsx";

const SelectContent = () => {
  const [selection, setSelection] = useState([]);

  const { saveSteps, nextWizardRoute } = useIngestionWizard();

  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate(nextWizardRoute());
  };

  const handleSkipClick = () => {
    navigate("/onboarding/subscription");
  };

  const navigateBack = () => {
    navigate("/onboarding/website-url");
  };

  const toggleSelect = selected => {
    let newSelection = [];

    if (selection.includes(selected)) {
      newSelection = selection.filter(
        selectedItem => selectedItem !== selected
      );
    } else {
      newSelection = [...selection, selected];
    }

    setSelection(newSelection);
    saveSteps(newSelection);
  };

  return (
    <div>
      <WizardStep
        handleNextClick={handleNextClick}
        handleSkipStepClick={handleSkipClick}
        handleBackClick={navigateBack}
        nextEnabled={selection.length > 0}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="w-full max-w-md px-4 mt-20">
            <h1 className="text-3xl mb-5 text-center w-full">
              Select content to train your new AI Support Team
            </h1>
          </div>

          <section className="pb-5">
            <h2 className="text-center text-bold">Business Support Email</h2>
            <div className="flex flex-wrap justify-center gap-4 max-w-sm">
              <button
                className={`px-5 py-2.5 text-base cursor-pointer border border-gray-300 rounded-md flex items-center ${
                  selection.includes("Gmail") ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  toggleSelect("Gmail");
                }}
              >
                Gmail
                <GmailIcon className="h-5 w-5 ml-2" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Microsoft
                <MicrosoftIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>
            </div>
          </section>

          <section className="pb-5">
            <h2 className="text-center font-bold">Help Desk</h2>
            <div className="flex flex-wrap justify-center gap-4 max-w-sm">
              <button
                className={`px-5 py-2.5 text-base cursor-pointer border border-gray-300 rounded-md flex items-center ${
                  selection.includes("Intercom") ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  toggleSelect("Intercom");
                }}
              >
                Intercom
                <IntercomIcon className="h-5 w-5 ml-2" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Zendesk
                <ZendeskIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Kustomer
                <KustomerIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Gorgias
                <GorgiasIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Live Chat
                <LiveChatIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" className="ml-2" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Hubspot
                <HubSpotIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" className="ml-2" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Tidio
                <TidioIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" className="ml-2" />
              </button>
            </div>
          </section>
          <section className="pb-5">
            <h2 className="text-center text-bold">Other</h2>
            <div className="flex flex-wrap justify-center gap-4 max-w-sm">
              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Notion
                <NotionIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Google docs
                <GoogleDocsIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>

              <button
                className={`px-5 py-2.5 text-base cursor-pointer border border-gray-300 rounded-md flex items-center ${
                  selection.includes("Upload file") ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  toggleSelect("Upload file");
                }}
              >
                Upload file
                <UploadFileIcon className="h-5 w-5 ml-2" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Website FAQ
                <WebsiteFAQIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>

              <button className="px-5 py-2.5 text-base border border-gray-300 rounded-md flex items-center opacity-50 cursor-not-allowed">
                Website Sitemap
                <WebsiteSitemapIcon className="h-5 w-5 ml-2" />
                <ComingSoonBadge name="Soon" />
              </button>
            </div>
          </section>
        </div>
      </WizardStep>
    </div>
  );
};

export default SelectContent;
