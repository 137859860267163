import React, { useEffect } from "react";
import { Dialog, DialogTitle, DialogBackdrop } from "@headlessui/react";
import { useForm } from "react-hook-form";

const UserModal = ({
  isOpen,
  onClose,
  onSubmit,
  defaultValues,
  title,
  isEdit = false,
  emailError,
  onEmailChange,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (isOpen && defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-0 transition-opacity" />
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
          <DialogTitle className="text-lg font-semibold mb-4">
            {title}
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <div>
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700"
                  disabled=""
                >
                  Role
                </label>
                <span className="mt-1 block w-full p-2 border-0.5 border-gray-300 bg-gray-100 rounded-md shadow-sm">
                  Member
                </span>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                {!isEdit ? (
                  <input
                    {...register("email", { required: "Email is required" })}
                    type="email"
                    className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm ${
                      errors.email || emailError ? "border-red-500" : ""
                    }`}
                    onChange={onEmailChange}
                  />
                ) : (
                  <span className="mt-1 block w-full p-2 border-0.5 border-gray-300 bg-gray-100 rounded-md shadow-sm">
                    {watch("email")}
                  </span>
                )}
                {errors.email && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.email.message}
                  </p>
                )}
                {emailError && (
                  <p className="text-sm text-red-600 mt-2">{emailError}</p>
                )}
              </div>

              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                First name
              </label>
              <input
                {...register("first_name", {
                  required: "First name is required",
                })}
                type="text"
                className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm ${
                  errors.first_name ? "border-red-500" : ""
                }`}
              />
              {errors.first_name && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.first_name.message}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <input
                {...register("last_name", {
                  required: "Last name is required",
                })}
                type="text"
                className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm ${
                  errors.last_name ? "border-red-500" : ""
                }`}
              />
              {errors.last_name && (
                <p className="mt-2 text-sm text-red-600">
                  {errors.last_name.message}
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                {...register("title")}
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleClose}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-emerald-500 text-white rounded-lg"
              >
                {isEdit ? "Save" : "Create"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default UserModal;
