const GlobalLoadingSpinner = ({ loading }) => {
  if (!loading) return null;
  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
      style={{ zIndex: 9999 }}
    >
      <div className="flex justify-center items-center h-full z-40">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-emerald-600" />
      </div>
    </div>
  );
};

export default GlobalLoadingSpinner;
