import React, { useRef, useState } from "react";

const ColorPicker = ({ color, setColor }) => {
  const hiddenColorInput = useRef(null);

  const handleInputChange = e => {
    const newColor = e.target.value;
    if (/^#([0-9A-Fa-f]{6})$/.test(newColor)) {
      setColor(newColor);
    }
  };

  const handleColorChange = e => {
    setColor(e.target.value);
  };

  const triggerColorPicker = () => {
    hiddenColorInput.current.click();
  };

  return (
    <div className="flex items-center space-x-4">
      <label className="text-sm text-gray-700 font-semibold">Custom</label>

      <div
        className="relative pl-1 pt-1 w-[2rem] h-[2rem] flex items-center justify-center cursor-pointer"
        onClick={triggerColorPicker}
      >
        <div
          className="absolute w-full h-full rounded-full"
          style={{
            border: `2px solid ${color}`,
          }}
        ></div>

        <div
          className="absolute w-[1.5rem] h-[1.5rem] rounded-full"
          style={{ background: color }}
        ></div>
      </div>

      <input
        type="color"
        ref={hiddenColorInput}
        value={color}
        onChange={handleColorChange}
        className="hidden"
      />

      <input
        type="text"
        value={color}
        onChange={handleInputChange}
        className="text-center w-24 border border-gray-300 rounded-lg px-4 py-2 text-sm focus:outline-none focus:ring focus:ring-purple-500"
      />
    </div>
  );
};

export default ColorPicker;
