import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/file.svg";
import { ReactComponent as HelpIcon } from "../../../imgs/icons/help.svg";

import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as BackButton } from "../../../imgs/icons/buttons/back.svg";

const AddPoliciesModal = ({
  isOpen,
  onClose,
  goBack,
  onSuccess,
  setLoading,
  api,
}) => {
  const [policies, setPolicies] = useState([
    {
      policy: "",
      answer: "",
      placeholder:
        "e.g. We allow refunds up to 30 days after the date of purchase. If you would like a refund, please ship back your item by following the instructions at example.com/return-instructions",
    },
  ]);

  if (!isOpen) return null;

  const actionDisabled = policies.some(
    policy => !policy.policy.trim() || !policy.answer.trim()
  );

  const repeatField = () => {
    setPolicies([...policies, { policy: "", answer: "", placeholder: "" }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedPolicies = [...policies];
    updatedPolicies[index][field] = value;
    setPolicies(updatedPolicies);
  };

  const removeField = index => {
    const updatedPolicies = policies.filter((_, i) => i !== index);
    setPolicies(updatedPolicies);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center">
        <TitleIcon width={60} height={60} className="p-4 ml-6 mt-6 border rounded-xl" />
        <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">
            Business Policies
          </h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            The policies you enter here will be respected and take priority over
            other information found on your website or terms. Please make sure
            these are as accurate as possible.
          </p>
        </div>

        <div className=" border-t border-1 border-red">
          {policies.map((policy, index) => (
            <div key={index} className="mb-4 mx-6">
              <div className="flex justify-between items-center">
                <div className="flex mt-6 mb-2 text-xs text-gray-800 font-semibold">
                  Policy *
                  <HelpIcon className="ml-1 w-4 h-4" />
                </div>
                {policies.length > 1 && index > 0 && (
                  <button
                    onClick={() => removeField(index)}
                    className="text-gray-400 hover:text-red-500 focus:outline-none"
                  >
                    &times;
                  </button>
                )}
              </div>

              <select
                className="px-2 py-3 rounded-md bg-white border border-gray-300 ring-opacity-50 w-full"
                value={policy.policy}
                onChange={e =>
                  handleInputChange(index, "policy", e.target.value)
                } // Updates state on selection
              >
                <option value="" disabled></option>
                <option value="Refund policy">Refund Policy</option>
                <option value="Exchange policy">Exchange Policy</option>
                <option value="Goodwill compensation policy">
                  Goodwill Compensation Policy
                </option>
              </select>

              <div className="flex mt-3 mb-2 text-xs text-gray-800 font-semibold">
                Answer *
                <HelpIcon className="ml-1 w-4 h-4" />
              </div>
              <textarea
                className="px-2 py-3 rounded-md border border-gray-300 ring-opacity-50 w-full"
                placeholder={policy.placeholder}
                rows={6}
                value={policy.answer}
                onChange={e =>
                  handleInputChange(index, "answer", e.target.value)
                }
              />
            </div>
          ))}

          <button
            onClick={repeatField}
            className="mx-6 my-3 text-sm text-secondary font-semibold hover:underline focus:outline-none"
          >
            + Add another
          </button>
        </div>

        <div className="flex items-center py-3">
          <button
            onClick={goBack}
            className="flex items-center justify-center w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            <BackButton className="mr-2" />
            Go Back
          </button>
          <button
            className={`flex items-center justify-center w-1/2 text-md leading-5 bg-secondary border border-primary shadow-md ${
              actionDisabled ? "cursor-not-allowed" : "hover:opacity-75"
            } font-bold text-white ml-1 mr-6 py-3 rounded-md`}
            disabled={true}
          >
            {policies.length === 1 ? "Add Policy" : "Add Policies"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPoliciesModal;
