import React from "react";

const GenericAvatar = () => {
  return (
    <div className="w-8 h-8 bg-emerald-500 text-white text-xl font-bold flex items-center justify-center rounded-full uppercase">
      U
    </div>
  );
};

export default GenericAvatar;

