import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/gmail.svg";

import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as BackButton } from "../../../imgs/icons/buttons/back.svg";

import { ReactComponent as GoogleIcon } from "../../../imgs/icons/google.svg";
import { ReactComponent as UploadFileIcon } from "../../../imgs/icons/uploadFile.svg";

import { authenticateWithParagon } from "../../../paragonAuthHelper.js";
import { paragon } from "@useparagon/connect";

const AddGmailModal = ({
  isOpen,
  onClose,
  goBack,
  onSuccess,
  loading,
  setLoading,
  api,
}) => {
  const handleGoogleSignIn = async () => {
    try {
      const companyId = sessionStorage.getItem("company_id");
      if (!companyId) {
        console.error("Company ID not found in sessionStorage");
        return;
      }

      await authenticateWithParagon(companyId);
      await paragon.connect("gmail", {
        onSuccess: () => {
          onSuccess();
        },
        onError: error => {
          alert("Error connecting to Gmail");
          console.error("Error connecting to Gmail:", error);
        },
      });
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };

  const callParagonWorkflow = async () => {
    try {
      setLoading(true);
      const response = await paragon.workflow(
        "256e5e7e-c01d-4e2f-97ba-9af28fa51a8a",
        {
          numberOfThreads: numberConversations,
        }
      );

      await api.post("/api/save-embedding", {
        data: response,
        source_type: "GMAIL",
      });

      alert("Paragon workflow response sent to backend");
    } catch (error) {
      console.error("Error calling Paragon workflow:", error);
      alert("Error calling Paragon workflow");
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async event => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await api.post("/api/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const [numberConversations, setNumberConversations] = useState(100);
  const [numberSelected, setNumberSelected] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
        <div className="flex justify-between items-center">
          <TitleIcon width={60} height={60} className="p-4 ml-6 mt-6 border rounded-xl" />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">
            Gmail Conversations
          </h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            Connect to your support team’s gmail account to pull in conversation
            history and train your AI Support Agent to better respond to
            customer inquiries.
          </p>
        </div>

        <div className="hidden text-center py-4">
          <div className="text-center py-4">
            <h2 className="text-xl font-semibold mb-4">
              Let's grab your Gmail support conversations to train your AI
              Support Team
            </h2>
            <div className="flex flex-col items-center space-y-4">
              <button
                className="py-2 px-4 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
                onClick={handleGoogleSignIn}
              >
                Sign in to Google
              </button>
              <button
                className={`py-2 px-4 bg-emerald-500 text-white rounded-md ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-emerald-600"}`}
                onClick={callParagonWorkflow}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload Gmail support history"}
              </button>
            </div>
            <a
              href="https://support.google.com/mail/answer/10759745"
              target="_blank"
              rel="noopener noreferrer"
              className="block mt-4 text-emerald-600 underline"
            >
              How to export email history from Gmail
            </a>
          </div>
        </div>

        {numberSelected && (
          <div className="flex flex-col">
            <div className="mx-6">
              <div className="flex flex-col items-center">
                <p className="mt-4">Pull conversations from Support Gmail</p>

                <button
                  className="bg-black text-sm font-bold text-white px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full justify-center mx-auto mb-2"
                  onClick={handleGoogleSignIn}
                >
                  Sign in to Google
                  <GoogleIcon className="h-5 w-5 ml-2" />
                </button>

                <label className="cursor-pointer text-sm font-bold text-gray-400 px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full justify-center mx-auto">
                  Upload email history file
                  <UploadFileIcon className="h-5 w-5 ml-2" />
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                </label>

                <a
                  href="https://support.google.com/mail/answer/10759745"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 underline font-bold text-xs pb-10 mx-auto text-center justify-center mt-5"
                >
                  How to export email history from Gmail
                </a>
              </div>
            </div>

            <div className="border-b mb-1"></div>

            <div className="flex justify-start py-3">
              <button
                onClick={() => setNumberSelected(false)}
                className="flex p-5 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
              >
                <BackButton className="mr-2" />
                Go Back
              </button>
            </div>
          </div>
        )}

        {!numberSelected && (
          <div>
            <div className="mb-4 mx-6">
              <div className="flex mt-6 mb-1 text-xs text-gray-600 font-semibold">
                How many conversations would you like us to pull?
              </div>
              <div className="flex mb-2 text-xs text-gray-600">
                The more information you provide your AI Agent, the better it
                will perform. All data is secured and can be deleted at any
                time.
              </div>
              <select
                className="mt-3 px-2 py-3 rounded-md bg-white border border-gray-300 ring-opacity-50 w-full"
                value={numberConversations}
                onChange={e => {
                  // handleInputChange(e.target.value)
                  setNumberConversations(e.target.value);
                }}
              >
                <option value="100">100 conversation threads</option>
                <option value="200">200 conversation threads</option>
                <option value="500">500 conversation threads</option>
              </select>

              <div className="flex mt-5 text-xs text-gray-600">
                Our AI will ignore all spam/incoming emails that have not been
                replied to.
              </div>
            </div>

            <div className="border-b mb-1"></div>

            <div className="flex justify-end py-3">
              <button
                onClick={goBack}
                className="flex p-5 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
              >
                <BackButton className="mr-2" />
                Go Back
              </button>
              <button
                className="flex p-5 text-md leading-5 bg-secondary border border-primary shadow-md hover:opacity-75 font-bold text-white ml-1 mr-6 py-3 rounded-md"
                onClick={() => setNumberSelected(true)}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddGmailModal;
