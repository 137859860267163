import React, { useEffect, useState, useRef } from "react";
import GlobalLoadingSpinner from "../../components/GlobalSpinner.jsx";

import UserModal from "./UserModal";

import api from "../../api.js";
import ErrorModal from "../../components/ErrorModal.jsx";
import InfoModal from "../../components/InfoModal.jsx";
import plainUserAvatar from "../../imgs/icons/emptyUserAvatar.png";

const Team = accountTopRef => {
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [teamMembers, setTeamMembers] = useState([]);

  const [editingUserId, setEditingUserId] = useState(null);
  const [editingUserData, setEditingUserData] = useState(null);

  const [emailTakenError, setEmailTakenError] = useState(null);

  const [errorModalMsg, setErrorModalMsg] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [infoModalMsg, setInfoModalMsg] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const scrollToTop = () => {
    if (accountTopRef.current) {
      accountTopRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchTeam = async () => {
    try {
      const response = await api.get(`/api/team`);
      const team = response.data;

      setTeamMembers(team);
      setLoading(false);
      scrollToTop();
    } catch (err) {
      setLoading(false);
      alert(JSON.stringify(err));
    }
  };

  useEffect(() => {
    fetchTeam();

    api.get(`/api/user`).then(response => {
      if (response.data && response.data.id) {
        setCurrentUserId(response.data.id);
      }
    });
  }, []);

  const handleAddUser = () => setIsNewUserModalOpen(true);

  const handleCloseNewUserModal = () => {
    setIsNewUserModalOpen(false);
  };

  const handleCreateUser = data => {
    setLoading(true);

    api
      .post("/api/team/add-member", data)
      .then(({ data }) => {
        setShowInfoModal(true);
        setInfoModalMsg(`User created with password: ${data.password}`);

        fetchTeam().then(() => {
          handleCloseNewUserModal();
        });

        setLoading(false);
      })
      .catch(e => {
        if (e.response.data.detail === "Email already in use") {
          setEmailTakenError(
            "Email is already taken. Please choose a different one."
          );
        } else {
          setIsNewUserModalOpen(false);
          setErrorModalMsg("Unexpected error - please try again later.");
          setShowErrorModal(true);
        }

        setLoading(false);
      });
  };

  const handleUpdateStatus = async (userId, active) => {
    if (
      !active &&
      !window.confirm("Are you sure you want to deactivate this user?")
    ) {
      return;
    }

    if (
      active &&
      !window.confirm("Are you sure you want to re-activate this user?")
    ) {
      return;
    }

    setLoading(true);

    await api.put(`/api/team/user/${userId}`, { active: active });
    await fetchTeam();

    setLoading(false);
  };

  const handleEditUser = async userId => {
    setLoading(true);
    try {
      const { data } = await api.get(`/api/team/user/${userId}`);

      setEditingUserData({
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        title: data.title || "",
        email: data.email || "",
      });

      setEditingUserId(data.id);

      setIsEditUserModalOpen(true);
      setLoading(false);
    } catch (err) {
      setErrorModalMsg("Error loading user data - please try again later.");
      setShowErrorModal(true);
    }
  };

  const handleEditSaveUser = async data => {
    setLoading(true);
    try {
      const updatedUser = {
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
      };

      await api.put(`/api/team/user/${editingUserId}`, updatedUser);

      setEditingUserId(null);

      await fetchTeam();

      setIsEditUserModalOpen(false);
    } catch (error) {
      setIsEditUserModalOpen(false);

      setErrorModalMsg("Error updating user data - please try again later.");
      setShowErrorModal(true);
    }

    setLoading(false);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <GlobalLoadingSpinner loading={loading} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Team Management
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Manage your team member accounts, including contact details.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={handleAddUser}
            className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          >
            Add User
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {teamMembers.length === 0 ? (
              <div className="text-center py-10">
                <h3 className="mt-2 text-sm font-semibold text-gray-900">
                  No team members
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by adding a new user to your team.
                </p>
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={handleAddUser}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-500"
                  >
                    Add User
                  </button>
                </div>
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {teamMembers.map((person, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              alt=""
                              src={person.picture_url || plainUserAvatar}
                              className="h-11 w-11 rounded-full"
                            />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">
                              {person.first_name} {person.last_name}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {person.email}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {person.active ? (
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Active
                          </span>
                        ) : (
                          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        Member
                      </td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-sm font-medium sm:pr-0">
                        <a
                          onClick={() => {
                            handleEditUser(person.id);
                          }}
                          className="text-emerald-600 hover:text-emerald-900 cursor-pointer"
                        >
                          Edit
                        </a>
                        {person.active && person.id !== currentUserId && (
                          <a
                            onClick={() => handleUpdateStatus(person.id, false)}
                            className="text-red-500 hover:text-red-900 cursor-pointer ml-3"
                          >
                            {" "}
                            Deactivate
                          </a>
                        )}

                        {!person.active && (
                          <a
                            onClick={() => handleUpdateStatus(person.id, true)}
                            className="text-emerald-600 hover:text-emerald-900 cursor-pointer ml-3"
                          >
                            {" "}
                            Activate
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <UserModal
        isOpen={isNewUserModalOpen}
        onClose={() => setIsNewUserModalOpen(false)}
        onSubmit={handleCreateUser}
        defaultValues={{ role: "Member" }}
        title="Add New User"
        emailError={emailTakenError}
        onEmailChange={() => setEmailTakenError(null)}
      />

      <UserModal
        isOpen={isEditUserModalOpen}
        onClose={() => setIsEditUserModalOpen(false)}
        onSubmit={handleEditSaveUser}
        defaultValues={editingUserData}
        title="Edit User"
        isEdit={true}
      />

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        errorMessage={errorModalMsg}
      />

      <InfoModal
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        message={infoModalMsg}
      />
    </div>
  );
};

export default Team;
