import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

const ConversationSummary = ({ conversation }) => {
  const [expandedSummaries, setExpandedSummaries] = useState({});

  const toggleSummary = (e, conversationId) => {
    e.stopPropagation();
    setExpandedSummaries(prevState => ({
      ...prevState,
      [conversationId]: !prevState[conversationId],
    }));
  };

  if (!conversation.summary) return "N/A";

  const isExpanded = expandedSummaries[conversation.conversation_uuid];
  const shortSummary = conversation.summary.substring(0, 100);

  return (
    <div>
      <span className="font-semibold max-w-full break-words">Summary: </span>
      {conversation.summary.length <= 30 || isExpanded ? (
        <>
          <ReactMarkdown>{conversation.summary}</ReactMarkdown>
          <button
            className="italic underline"
            onClick={e => toggleSummary(e, conversation.conversation_uuid)}
          >
            (show less)
          </button>
        </>
      ) : (
        <>
          {shortSummary}...
          <button
            className="italic underline"
            onClick={e => toggleSummary(e, conversation.conversation_uuid)}
          >
            (show more)
          </button>
        </>
      )}
    </div>
  );
};

export default ConversationSummary;
