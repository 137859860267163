import React from "react";
import { Link } from "react-router-dom";
import customerImage from "./customer-image.png";
import createChatbot from "./create-chatbot-text.png";
import logo from "../logo.png";

const Landing = () => {
  return (
    <div className="container mx-auto text-center p-4">
      <header className="landing-header flex items-center justify-between p-4 relative">
        <img src={logo} alt="Papaya Logo" className="logo-img h-10 md:h-12" />
        <Link to="/onboarding/signin">
          <button className="font-semibold learn-more-button ml-4 px-6 py-2 border border-gray-300 rounded-lg text-gray-400 bg-transparent hover:bg-gray-200 text-sm font-plus-jakarta-sans">
            Sign in →
          </button>
        </Link>
      </header>
      <main className="main-content py-12 flex flex-col justify-center items-center">
        <img
          src={createChatbot}
          className="main-heading w-full max-w-lg mx-auto"
          alt="Create your chatbot"
        />
        <p className="text-sm text-gray-600 my-2 mx-6 md:mx-0 font-plus-jakarta-sans">
          Varius eros dictum faucibus sed non porttitor aliquet enim.
        </p>
        <Link to="/onboarding/signup" className="flex justify-center">
          <button className="get-started-button mt-4 text-white bg-[#30a46c] border-none rounded-[4px] cursor-pointer w-[150px] px-4 py-4">
            Get Started
          </button>
        </Link>
      </main>
      <section className="customer-image-section py-4">
        <img
          src={customerImage}
          alt="customer-typing"
          className="customer-image w-full max-w-full mx-auto rounded-lg"
        />
      </section>
      <div className="mb-[75px] md:mb-[100px] lg:mb-[150px]"></div>
    </div>
  );
};

export default Landing;
