import api from './api';
import { paragon } from '@useparagon/connect';

export const getParagonAuthToken = async (companyId) => {
  try {
    const response = await api.post('/generate-jwt', { sub: companyId });
    return response.data.token;
  } catch (error) {
    console.error('Error generating Paragon auth token:', error);
    throw error;
  }
};

export const authenticateWithParagon = async (companyId) => {
  try {
    const token = await getParagonAuthToken(companyId);
    await paragon.authenticate(
      '3e17e573-44cd-4431-b013-44eacfdc3f80',
      token
    );
    console.log('Authenticated with Paragon successfully');
  } catch (error) {
    console.error('Error during Paragon authentication:', error);
    throw error;
  }
};
