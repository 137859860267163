import React from "react";
import ThreadCard from "./ThreadCard.jsx";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const MessageList = ({
  selectedThread,
  threads,
  fetchThread,
  totalThreads,
  currentPage,
  currentPageSize,
  setPageSize,
  setPage,
  totalPages,
  handleNextPage,
  handlePreviousPage,
  filters,
  setFilters,
  showFilters,
}) => {
  const handlePageSizeChange = event => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  return (
    <div className={`min-w-[300px] ${selectedThread && "hidden sm:block"}`}>
      {showFilters && (
        <div className="flex justify-between mx-3 my-3">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {filters.handedOff ? "Handed Off" : "All"}
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 h-5 w-5 text-gray-400"
                />
              </MenuButton>
            </div>
            <MenuItems className="absolute left-0 z-10 mt-2 w-28 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ ...filters, handedOff: true });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      Handed Off
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ ...filters, handedOff: false });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      All
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {filters.resolved
                  ? "Closed"
                  : filters.resolved === false
                    ? "Open"
                    : "All"}
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 h-5 w-5 text-gray-400"
                />
              </MenuButton>
            </div>
            <MenuItems className="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ ...filters, resolved: true });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      Closed
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ ...filters, resolved: false });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      Open
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ ...filters, resolved: null });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      All
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      )}

      <ul className="">
        {threads.map(thread => (
          <ThreadCard
            key={thread.conversation_id}
            thread={thread}
            selected={
              selectedThread &&
              selectedThread.conversation_id === thread.conversation_id
            }
            selectMessage={thread => {
              fetchThread({ thread: thread });
            }}
          />
        ))}
      </ul>

      {totalPages > 1 && (
        <div className="flex flex-col items-center justify-between border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex items-center">
            <p className="text-sm text-gray-700">
              Page <span className="font-medium">{currentPage}</span> of{" "}
              <span className="font-medium">{totalPages}</span>
            </p>
          </div>

          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <nav
                aria-label="Pagination"
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              >
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
                </button>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setPage(index + 1)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      currentPage === index + 1
                        ? "bg-emerald-600 text-white"
                        : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    } focus:z-20 focus:outline-offset-0`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalThreads}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
                </button>
              </nav>
            </div>
          </div>

          <div className="flex items-center mt-2 pt-2">
            <label htmlFor="page-size" className="mr-2 text-sm text-gray-700">
              Page size:
            </label>
            <select
              id="page-size"
              value={currentPageSize}
              onChange={handlePageSizeChange}
              className="border-gray-300 rounded-md text-sm p-1.5"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageList;
