import React, { useState } from "react";
import SetupTab from "./components/SetupTab.jsx";

import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";

import { WIDGET_URL } from "../../api.js";

const Deploy = () => {
  const [isCopied, setIsCopied] = useState(false);

  const chatbotUuid = localStorage.getItem("chatbot_uuid") || "";

  // TODO: fetch from company (depends on Branding page)
  const backgroundColor = "#FFFFFF";
  const secondaryColor = "#4F8F00";
  const fontColor = "#000000";
  const companyName = "Sample company name";
  const companyLogo =
    "https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png";
  // ~ TODO: fetch from company (depends on Branding page)

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  const embedCode = `<iframe
  id="bonsai-widget-iframe"
  src="${WIDGET_URL}/widgetEntry.html"
  title="Live Chat Widget"
></iframe>

<script>
  document.addEventListener("DOMContentLoaded", () => {
    const iframe = document.getElementById("bonsai-widget-iframe");

    Object.assign(iframe.style, {
      border: "none",
      width: "500px",
      height: "650px",
      position: "fixed",
      bottom: "5px",
      right: "5px",
      zIndex: "9999",
    });

    iframe.onload = () => {
      iframe.contentWindow.postMessage(
        {
          type: "SET_CUSTOM_OPTIONS",
          tenantId: "${chatbotUuid}",
          backgroundColor: "${backgroundColor}",
          secondaryColor: "${secondaryColor}",
          fontColor: "${fontColor}",
          companyName: "${companyName}",
          companyLogo: "${companyLogo}"
        },
        "*"
      );
    };
  });
</script>
    `;

  return (
    <div className="mt-12 mx-4 md:mx-24">
      <SetupTab
        title={"Deploy"}
        subtitle={
          "Use this to deploy your new AI employee to your communication channels. Livechat and email are currently supported and more channels such as SMS, WhatsApp, Facebook Messenger and Instagram Messenger are coming soon."
        }
        activeTab={"deploy"}
      />

      {/* Livechat Embed Code Section */}
      <section className="mt-8">
        <h2 className="text-lg font-semibold mb-0">Livechat</h2>
        <p className="text-gray-600 text-sm">
          Name your new AI employee so they can have a personality. Or you can
          simply choose to name them after your business.
        </p>

        <div className="mt-6">
          {/* // TODO: consider extracting */}
          <style>
            {`
          .custom-select {
            appearance: none;
            border: 1px solid #b0b0b0;
            padding-right: 2.5rem;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg height='36' width='36' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 8L12 13L17 8' stroke='%23666' stroke-width='2' fill='none'/%3E%3C/svg%3E"); background-repeat: no-repeat;
            background-position: right 0.5rem center;
            background-size: 1rem;
          }
          .custom-select:focus {
            outline: none;
            border-color: #666;
          }
        `}
          </style>

          <label className="block text-sm font-medium text-gray-700">
            Install Type
          </label>
          <select
            disabled="true"
            className="custom-select text-gray-500 mt-1 block min-w-56 lg:min-w-64 py-2 px-3 400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
          >
            <option>Embed on Website</option>
          </select>

          <p className="text-gray-600 text-sm mt-2">
            Select your installation method above
          </p>
        </div>

        <div className="flex">
          {/* Line Numbers Container */}
          <div className="text-white w-full border-2 rounded-2xl flex-shrink-0">
            <SyntaxHighlighter
              language="javascript"
              style={solarizedlight}
              showLineNumbers={true}
              customStyle={{
                backgroundColor: "white",
                padding: "20px",
                minWidth: "40px", // Keeps the line number column from shrinking
                marginRight: "10px",
                whiteSpace: "normal", // Ensures text wraps
              }}
            >
              {embedCode}
            </SyntaxHighlighter>
          </div>
        </div>

        <div className="flex justify-end items-centerm mt-8">
          {isCopied && <span className="mr-2 text-gray-800 mt-3">Copied!</span>}
          <CopyToClipboard text={embedCode} onCopy={handleCopy}>
            <button className="px-4 py-2 border-2 border-gray-300 text-xs font-bold text-gray-700 rounded-md shadow-sm hover:bg-gray-50 flex items-center space-x-1">
              <DocumentDuplicateIcon
                aria-hidden="true"
                className="h-6 w-6 text-gray-700"
              />
              <span>Copy</span>
            </button>
          </CopyToClipboard>
        </div>
      </section>

      {/* <section className="mt-8">
        <h2 className="text-xl font-semibold">Email</h2>
        <p className="text-gray-600">
          Connect your Gmail or Outlook accounts to enable your new AI employee to reply to customer emails for you. Only connect your main support inbox.
        </p>

        <div className="mt-4 flex gap-6">
          <div className="w-1/2 border p-4 rounded-md flex flex-col items-center">
            <img src="/path/to/gmail-logo.png" alt="Gmail Logo" className="h-10 mb-4" />
            <p className="text-gray-600 mb-2 text-center">
              Connect your Gmail to automate your customer support emails.
            </p>
          </div>

          <div className="w-1/2 border p-4 rounded-md flex flex-col items-center">
            <img src="/path/to/outlook-logo.png" alt="Outlook Logo" className="h-10 mb-4" />
            <p className="text-gray-600 mb-2 text-center">
              Connect your business outlook account to automate your customer support emails.
            </p>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Deploy;
