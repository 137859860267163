import React, { useState } from "react";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import logo from "../logo.png";

const Signup = ({ sign_in = false }) => {
  const { signInWithGoogle, signUpWithEmail, signInWithEmail } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(!sign_in);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error("Error during Google Sign-In process:", error);
    }
  };

  const handleEmailSignUp = async e => {
    e.preventDefault();
    setErrorMessage("");
    try {
      await signUpWithEmail(email, password);
      navigate("/callback"); // Navigate to callback on successful signup
    } catch (error) {
      console.error("Error during Email Sign-Up process:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleEmailSignIn = async e => {
    e.preventDefault();
    setErrorMessage("");
    try {
      await signInWithEmail(email, password);
      navigate("/callback"); // Navigate to callback on successful sign in
    } catch (error) {
      console.error("Error during Email Sign-In process:", error);
      setErrorMessage("Invalid email or password. Please try again.");
    }
  };

  return (
    <div className="flex min-h-full flex-1 bg-[#F0F0EF]">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              {isSigningUp
                ? "Signup to Transform Your Support"
                : "Sign in to Your Account"}
            </h2>
          </div>

          <div className="mt-6 grid grid-cols gap-4">
            <button
              onClick={handleGoogleSignIn}
              className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
            >
              <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                <path
                  d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  fill="#EA4335"
                />
                <path
                  d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  fill="#4285F4"
                />
                <path
                  d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  fill="#34A853"
                />
              </svg>
              <span className="text-sm font-semibold leading-6">
                {isSigningUp ? "Sign up with Google" : "Sign in with Google"}
              </span>
            </button>
          </div>

          <div className="mt-10">
            <div className="relative">
              <div
                aria-hidden="true"
                className="absolute inset-0 flex items-center"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">
                  Or continue with
                </span>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <div>
              <form
                onSubmit={isSigningUp ? handleEmailSignUp : handleEmailSignIn}
                className="space-y-6"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="email@papaya.co"
                      className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="********"
                      className="block w-full rounded-md border-0 p-2 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {/*

                    TODO: I suggest we reinstate when we actually support the feature, perhaps not needed for MVP?

                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-700"
                    >
                      Remember me
                    </label>
                    */}
                  </div>

                  <div className="text-sm leading-6">
                    <button
                      type="button"
                      onClick={() => navigate("/recover-password")}
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot password?
                    </button>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#30a46c] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {isSigningUp ? "Sign up" : "Sign in"}
                  </button>
                </div>
              </form>
              {errorMessage && (
                <p className="mt-3 text-red-500 text-center font-semibold text-indigo-600 hover:text-indigo-500 error-message">
                  {errorMessage}
                </p>
              )}

              <div>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  {isSigningUp
                    ? "Already have an account? "
                    : "Don't have an account? "}

                  <button
                    type="button"
                    onClick={() => setIsSigningUp(!isSigningUp)}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    {isSigningUp ? "Login here" : "Signup here"}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:flex lg:items-center lg:justify-start lg:m-20">
        <div className="">
          <div className="">
            <img src={logo} alt="Papaya Logo" />
          </div>
          <h1 className="mt-5 text-5xl font-semibold">Get started for free!</h1>
          <p className="text-zinc-500">
            No credit card required. Companies using Papaya save up to
            $2,000,000 per year in annual support expenses.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
