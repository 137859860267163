import React from "react";
import ReactMarkdown from "react-markdown";

import { ShieldCheckIcon, CpuChipIcon } from "@heroicons/react/20/solid";

import GenericAvatar from "./GenericAvatar";

const ThreadMessages = ({ messages }) => {
  return (
    <>
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex my-5 ${
            message.sender === "user" ? "justify-start" : "justify-end"
          }`}
        >
          <div className={`flex items-center max-w-[75%]`}>
            <div className="flex items-center">
              {message.sender === "user" && <GenericAvatar />}
            </div>
            <div
              className={`mx-1 p-4 rounded-lg ${
                message.sender === "user"
                  ? "bg-gray-200 text-gray-900"
                  : message.sender === "admin"
                    ? "bg-[#05C2FF] text-gray-900"
                    : "bg-[#057EFF] text-white"
              }`}
            >
              <div>
                <ReactMarkdown>{message.message}</ReactMarkdown>
              </div>

              <div
                className={`text-xs mt-1 ${
                  message.sender === "ChatBot"
                    ? "text-gray-200"
                    : message.sender === "admin"
                      ? "text-gray-100"
                      : "text-gray-500"
                }`}
              >
                {message.sentTime}
              </div>
            </div>

            <div className="flex items-center">
              {message.sender === "admin" && (
                <div className="w-8 h-8 bg-[#05C2FF] text-white text-xl font-bold flex items-center justify-center rounded-full uppercase">
                  <ShieldCheckIcon className="h-6 w-6 text-gray-700 flex-shrink-0" />
                </div>
              )}
              {message.sender === "ChatBot" && (
                <div className="w-8 h-8 bg-[#057EFF] text-white text-xl font-bold flex items-center justify-center rounded-full uppercase">
                  <CpuChipIcon className="h-6 w-6 text-white flex-shrink-0" />
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ThreadMessages;
