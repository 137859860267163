import React, { useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogBackdrop,
} from "@headlessui/react";

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

// TODO: Use react hook form
const IntentFormDialog = ({
  isVisible,
  editingIntent,
  formData,
  handleInputChange,
  handleCreateOrUpdate,
  handleCancel,
}) => {
  return (
    <Dialog open={isVisible} onClose={handleCancel} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <DialogPanel className="bg-white rounded-lg w-[500px] p-4 shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
          <DialogTitle as="h2" className="text-xl font-semibold mb-4">
            {editingIntent ? "Edit Intent" : "Create New Intent"}
          </DialogTitle>

          <form className="space-y-4" onSubmit={handleCreateOrUpdate}>
            <div>
              <label
                htmlFor="type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Type
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  id="type"
                  name="type"
                  type="text"
                  value={formData.type}
                  onChange={handleInputChange}
                  className={`${editingIntent ? "bg-gray-100" : ""} block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                  disabled={!!editingIntent}
                  required
                />
                {formData.type.length === 0 && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-red-500"
                    />
                  </div>
                )}
              </div>
              {formData.type.length === 0 && (
                <p id="email-error" className="mt-2 text-sm text-red-600">
                  Required field.
                </p>
              )}
            </div>

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Description
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="h-24 resize-none block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="response"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Response
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <textarea
                  id="response"
                  name="response"
                  value={formData.response}
                  onChange={handleInputChange}
                  className="h-24 resize-none block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg mr-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600"
              >
                {editingIntent ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default IntentFormDialog;
