import { createClient } from "@supabase/supabase-js";
const supabaseUrl =
  process.env.REACT_APP_SUPABASE_URL ||
  "https://smtylynvqtoxoyrngucu.supabase.co";
const supabaseKey =
  process.env.REACT_APP_SUPABASE_KEY ||
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNtdHlseW52cXRveG95cm5ndWN1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTUzNzczNzgsImV4cCI6MjAzMDk1MzM3OH0.9tgBV6hWBCsxqpxV28jOCPfoSNGYJ3HDiFdzw8dUdJM";
export const supabase = createClient(supabaseUrl, supabaseKey);

// need to use env variables here
