import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api.js";
import WizardStep from "./WizardStep.jsx";

const useCaseMap = {
  None: 0,
  "Customer Support": 1,
  Sales: 2,
  "Employee Onboarding": 3,
};

const UseCaseSelection = () => {
  const [selectedUseCase, setSelectedUseCase] = useState("");
  const navigate = useNavigate();

  const handleNextClick = async () => {
    const companyId = sessionStorage.getItem("company_id"); // Retrieve Company ID from session storage

    if (!companyId) {
      console.error("Company ID not found in session storage");
      return;
    }

    if (selectedUseCase) {
      const useCaseId = useCaseMap[selectedUseCase];
      console.log(
        `Selected use case: ${selectedUseCase} with ID: ${useCaseId}`
      );

      try {
        await api.put(`/api/company/${companyId}`, {
          use_case: useCaseId,
        });
        navigate("/onboarding/website-url");
      } catch (error) {
        console.error("Error saving use case:", error);
      }
    } else {
      console.log("No use case selected");
    }
  };

  const handleSkipStepClick = () => {
    navigate("/onboarding/website-url");
  };

  return (
    <div>
      <WizardStep
        handleNextClick={handleNextClick}
        handleSkipStepClick={handleSkipStepClick}
        handleBackClick={() => {}}
        showBackOption={false}
        nextEnabled={!!selectedUseCase}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="w-full max-w-sm px-4 mt-20">
            <h1 className="text-3xl mb-5 text-center w-full">
              Train your new AI Support Team in under 5 minutes
            </h1>

            <p className="text-lg mb-5 text-center">Select a use case</p>

            <div className="flex flex-wrap justify-center gap-2.5 mb-5 w-full max-w-md text-[#8D8D86]">
              <button
                className={`px-5 py-2.5 text-base cursor-pointer border border-gray-300 rounded-md ${
                  selectedUseCase === "Customer Support" ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedUseCase("Customer Support")}
              >
                Customer Support
              </button>
              <button
                className={`px-5 py-2.5 text-base cursor-pointer border border-gray-300 rounded-md ${
                  selectedUseCase === "Sales" ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedUseCase("Sales")}
              >
                Sales
              </button>
              <button
                className={`px-5 py-2.5 text-base cursor-pointer border border-gray-300 rounded-md ${
                  selectedUseCase === "Employee Onboarding" ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedUseCase("Employee Onboarding")}
              >
                Employee Onboarding
              </button>
            </div>
          </div>
        </div>
      </WizardStep>
    </div>
  );
};

export default UseCaseSelection;
